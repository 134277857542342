<script>
import axios from "axios";

/**
 * 用于展示从后端发送的json内容，然后展示
 * 其中需要父组件，传递正确的值，如 solution、news 本组件使用该传递的值进行处理展示
 */
export default {
  name: "subject_temp_view",
  props: {
    post_name: String,
    is_at_homepage: Boolean,
  },
  data() {
    return {
      subject_texts: [],
      currentPage: 1, //当前页数
      itemsPerPage: 6, //每页显示的项目数
    }
  },
  computed: {
    groupedItems() {
      const groupd = [];
      for (let i = 0; i < this.subject_texts[0].article_component.length; i += this.itemsPerPage) {
        groupd.push(this.subject_texts[0].article_component.slice(i, i + this.itemsPerPage));
      }
      return groupd;
    },
    currentItems() {
      return this.groupedItems[this.currentPage - 1] || [];
    },
    totalPages() {
      return this.groupedItems.length;
    }
  },
  methods: {
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    getMessage() {
      // console.log(this.subject_texts)   // 打印原始的subject数据

      const path = 'http://120.26.199.34:5000/subject/' + this.post_name;
      axios.get(path).then(res => {

        const getMessageData = res.data;

        // console.log(getMessageData);      // 查看接收到的数据

        this.subject_texts = [{
          id: getMessageData.id,
          title: getMessageData.title,
          route: getMessageData.route,
          article_component: [],
          option: {
            if_title_decoration: getMessageData.option.if_title_decoration,
          }
        }]

        // 循环遍历 这个数组中的数据，并将数据复制到subject_texts中
        getMessageData.article_component.slice().reverse().forEach(item => {
          item.pacture_path = "http://120.26.199.34:5000/image/" + this.post_name + "/" + item.filename + "/" + item.pacture_path;
          // console.log(item);    // 查看修改后的元素是否正确
          this.subject_texts[0].article_component.push(item);
        })
        // console.log(this.subject_texts);
      }).catch(error => {
        console.error("无法收到请求:", error);
      })
    },
    page_route(path, opt, edit_type) {
      if (edit_type == "markdown") {
        this.$router.push('/viewpage/' + path + '?key=' + opt)
      } else if (edit_type == "html") {
        this.$router.push('/viewhtml/' + path + '?key=' + opt)
      }
    }
  },
  created() {
    this.getMessage();
    if(this.is_at_homepage){
      this.itemsPerPage = 3;
    }
  }
}
</script>

<template>
  <div class="subject_count scroll_target" v-for="subject_text in subject_texts" :key="subject_text.id"
       ref="scroll_animation">
    <h1>{{ subject_text.title }}</h1>
    <div class="title_decoration" v-if="subject_text.option.if_title_decoration"></div>
    <div v-else class="title_no_decoration"></div>
    <p v-if="is_at_homepage" class="route_page"><a :href="subject_text.route">更多内容 -&gt;&gt;</a> </p>
    <div class="article">
      <div class="article_com scroll_target" v-for="com in currentItems" :key="com.component_title"
           ref="scroll_animation">
        <div @click="page_route(com.filename, post_name, com.edit_type)" class="article_pacture"
             :style="{backgroundImage: `url(${com.pacture_path})`}"></div>
        <p class="article_from">By:{{ com.author }} / {{ com.release_date }}</p>
        <h2 @click="page_route(com.filename, post_name, com.edit_type)">{{ com.component_title }}</h2>
        <p class="article_text" v-for="(text, index) in com.article" :key="index">{{ text }}</p>
      </div>
    </div>
    <div v-if="!is_at_homepage" class="pagination">
      <button @click="prevPage" :disabled="currentPage === 1">上一页</button>
      <span>第 {{ currentPage }} 页 / 共 {{ totalPages }} 页</span>
      <button @click="nextPage" :disabled="currentPage === totalPages">下一页</button>
    </div>
  </div>
</template>

<style scoped>
.article {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  margin: auto;
  width: 1200px;
}

@media screen and (max-width: 1100px) {
  .article {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .article {
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .article {
    grid-template-columns: 1fr;
    width: 100%;
  }
}

.subject_count > h1 {
  margin-top: 30px;
  text-align: center;
}

.title_decoration {
  background-color: #f19204;
  width: 90px;
  margin: 10px auto 60px auto;
  height: 3px;
}

.title_no_decoration {
  margin: 0 auto 50px 0;
}

.article_pacture {
  height: 300px;
  background-position: center;
  cursor: pointer;
  background-size: 150%;
  background-repeat: no-repeat;
  transition: background-size 0.5s ease;
}

.article_pacture:hover {
  background-size: 160%;
}

.article_from {
  margin: 10px auto 10px auto;
  color: rgba(99, 99, 108, 0.65);
  font-size: 15px;
}

.article_com > h2 {
  margin-bottom: 10px;
  transition: color 0.5s ease-out;
  cursor: pointer;
}

.article_com > h2:hover {
  color: #f19204;
}

.article_text {
  font-size: 14px;
  line-height: 23px;
  color: rgba(14, 14, 19, 0.65);
}

.pagination {
  margin: 40px auto;
  text-align: center;
}

.pagination button {
  color: #ffffff;
  background-color: #f19204;
  height: 30px;
  width: 90px;
  margin: auto 20px;
  border: none;
}
.route_page{
  width: 1200px;
  margin: 0 auto 20px auto;
  text-align: right;
}
.route_page a{
  color: #63636c;
  text-decoration: none;
  transition: 1s color;
  font-size: 15px;
  font-weight: bold;
}
.route_page a:hover{
  color: #ffb300;
}
</style>