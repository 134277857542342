<script>
export default {
  name:"about_page",
  data(){
    return{
      page_texts:[{
          title: "关于谱康",
          Sublabel: "",
          text: [
              "安徽中科谱康科技有限公司是一家专注于工业过程分析检测的整体解决方案提供商，业务范围涵盖了自主研发和生产的多系列高端近红外光谱分析系统，公司核心技术人员为等离子体物理研究所光谱分析技术研究团队。",
              "针对当前化工、医药制造和食品加工等领域产业升级的迫切需求，公司致力于智能化工业在线监测所需的高品质光谱分析设备的研发、生产、销售及技术支持，为客户提供优质稳定可靠的智能化分析技术、设备及整体解决方案。"
          ],
          picture_path: [require("@/assets/about_temple/cert3.jpg"), require("@/assets/about_temple/cert4.png")],
          option: {
            if_title_decoration: false,
          }
        },
        {
          title: "产品技术",
          Sublabel: "在线近红外光谱仪",
          text: [""],
          picture_path: [require("@/assets/about_temple/production.png")],
          option: {
            if_title_decoration: true,
          }
        }]
    }
  }
}
</script>
<template>
  <div class="about-content" v-for="page_text in page_texts" :key="page_text.title">
    <h1>{{page_text.title}}</h1>
    <div class="title_decoration" v-if="page_text.option.if_title_decoration"></div>
    <div v-else style="margin-bottom: 60px"></div>
    <h3>{{page_text.Sublabel}}</h3>
    <p v-for="(text, index) in page_text.text" :key="index">{{text}}</p>
    <div class="picture_box" >
      <div v-for="(path, index) in page_text.picture_path" :key="index" :style="{background: `url(${path})`}" style="background-size: contain;background-repeat: no-repeat;background-position: center"></div>
    </div>
  </div>
</template>

<style scoped>
.about-content{
  display: flex;   /*为page_text这个元素可以填充剩余空间*/
  display: -webkit-flex;
  display: -ms-flex;
  display: -moz-flex;
  flex-direction: column;
  height: 700px;
}
.about-content > h1{
  text-align: center;
  margin-top: 80px;
}
.about-content > .title_decoration{ /*标题装饰*/
  width: 80px;
  height: 3px;
  background: #fd710d;
  margin: 10px auto 40px auto;
}
.about-content > h3{
  text-align: center;
  font-weight: normal;
}
.about-content > p{
  color: rgb(99, 99, 108);
  font-size: 15px;
  text-indent: 40px;
  line-height: 30px;
  margin: 0 auto;
  display: inline-block;
  width: 1200px;
}
.picture_box{
  flex: 1;      /* 填充*/
  display: flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: -moz-flex;
  margin: auto;
  width: 90%;
  max-width: 1400px;
}
.picture_box > *{ /*这些元素的属性无法使用background来调整图片，需要在template中调整*/
  flex: 1;
 margin: 40px;
}
</style>