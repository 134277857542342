<template>
  <div class="header-bottom" ref="float_top">
    <div class="header-bottom-right">
      <img class="menu-logo" src="@/assets/logo.jpg" />
    </div>
    <div class="header-bottom-left">
      <ul class="main-menu" v-if="ishid">
        <li v-for="linkitem in linkPage" :key="linkitem.name">
          <a :href="linkitem.link">{{linkitem.text}}</a>
        </li>
      </ul>
      <ul class="hide-main-menu" v-else>
        <li v-for="linkitem in linkPage" :key="linkitem.name">
          <a :href="linkitem.link">{{linkitem.text}}</a>
        </li>
      </ul>
      <div class="hide-menu" @click="menuDisplay">
        <img src="@/assets/list.png" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "head_bottom",
  data(){
    return{
     linkPage:[
       { name: "index", text: "首页", link: "/" },
       { name: "about", text: "关于谱康", link: "/about" },
       { name: "technology", text: "产品技术", link: "/product" },
       { name: "plan", text: "解决方案", link: "/solution" },
       { name: "news", text: "新闻中心", link: "/news" },
       { name: "contact", text: "联系我们", link: "/contact" },
     ],
      isbeclick: false,
      ishid: true,
    }
  },
  mounted() {
    // 用来今天滚轮滚动
    window.addEventListener('scroll', this.handleScroll);

    this.handleResize()
    // 用来监听屏幕大小变化
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    // 组件销毁后，销毁监听
    window.removeEventListener('scroll', this.handleScroll);
    // 用于销毁
    window.removeEventListener("resize", this.handleResize)
  },
  methods: {
    handleScroll() {
      const scroll_position = window.scrollY || document.documentElement.scrollTop;
      const head_log = this.$refs.float_top;
      if(scroll_position > 300){
        head_log.classList.add("float_top");
      }else{
        head_log.classList.remove("float_top")
      }
    },
    handleResize(){
     if(window.innerWidth < 900){
       this.ishid = false;
     }else{
       this.ishid = true;
     }
    },
    menuDisplay(){
      const ele = document.querySelector(".hide-main-menu");
      if(this.isbeclick){
        this.isbeclick = !this.isbeclick;
        ele.style.display = "none";
      }else{
        this.isbeclick = !this.isbeclick;
        ele.style.display = "inline-block"
      }
    }
  },
}
</script>
<style>
.header-bottom{
  box-shadow: 2px 2px 3px rgba(149, 149, 149, 0.47);
  height: 100px;
  display: flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: -moz-flex;
  background-color: #ffffff;
}
.float_top{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 11;
}
.header-bottom-right{
  width: 20%;
}
.header-bottom-right > img{
  float: right;
}
.header-bottom-left{
  width: 80%;
  position: relative;
}
.main-menu{
  display: flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: -moz-flex;
  justify-content: center; /* 水平居中 */
  align-items: center;    /* 垂直居中 */
  gap: 30px;
  margin-top: 40px;
}
.hide-menu{
  display: none;
}
.hide-main-menu{
  display: none;
  position: absolute;
  z-index: 12;
  background-color: #ffffff;
  padding: 10px;
  width: 150px;
  right: 60px;
  top: 80px;
  box-shadow: 2px 2px 5px #63636c;
}
@media screen and (max-width: 900px) {
  .header-bottom-right{
    width: 40%;
  }
  .header-bottom-left{
    width: 60%;
    position: relative;
  }
  .main-menu{
    display: none;
  }
  .hide-menu{
    display: inline-block;
    position: absolute;
    top: 30px;
    right: 100px;
    cursor: pointer;
    transition: all 0.5s ease-out;
  }
  .hide-menu:hover{
    transform: rotate(180deg);
  }
}
.main-menu > li{
  list-style-type: none;
}
.main-menu > li > a{
  color: #63636c;
  text-decoration: none;
  transition: 1s color;
  font-size: 15px;
  font-weight: bold;
}
.main-menu > li > a:hover{
  color: #ffb300;
}
.hide-main-menu > li {
  list-style-type: none;
  padding: 10px 5px;
}
.hide-main-menu > :not(:first-child){
  border-top: #63636c solid 1px;
}
.hide-main-menu > li > a{
  color: #63636c;
  text-decoration: none;
  font-weight: bold;
}
.hide-main-menu > li:hover{
  background-color: #63636c;
}
.hide-main-menu > li:hover a{
  color: white;
}
</style>