import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import aboutPage from '../subpage/aboutroute/aboutPage.vue';
import productPage from "@/subpage/productroute/productPage.vue";
import solutionPage from "@/subpage/solutionroute/solutionPage.vue"
import newsPage from "@/subpage/newsroute/newsPage.vue";
import contacPage from "@/subpage/contactroute/contacPage.vue";
import articleTemplate from "@/views/articleTemplate.vue";
import wageditor_test from "@/views/wageditor_test.vue";
import articleHtmlTemplate from "@/views/articleHtmlTemplate.vue"
// import MapContainer from "@/views/MapContainer.vue";

const routes = [
    { path: '/', name: 'Home', component: HomePage },
    { path: '/about', name: 'About', component: aboutPage },
    { path: '/product', name: 'product', component: productPage},
    { path: '/solution', name: 'solution', component: solutionPage},
    { path: '/news', name: 'news', component: newsPage},
    { path: '/contact', name: 'contact', component: contacPage},
    { path: '/viewpage/:words', name: 'viewpage', component: articleTemplate},
    { path: '/viewhtml/:words', name: 'viewhtml', component: articleHtmlTemplate},
    { path: '/test_form_edit' , name: 'form_edit', component: wageditor_test},
    // { path: '/map', name: 'map', component: MapContainer}
    // 在此处添加更多路由
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
