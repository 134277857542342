<template>
  <div class="upload-form">
    <form @submit.prevent="handleSubmit">
      <div class="form-group">
        <label for="title">标题:</label>
        <input type="text" id="title" v-model="formData.title" required>
      </div>

      <div class="form-group">
        <label for="summary">摘要:</label>
        <textarea id="summary" v-model="formData.summary" required></textarea>
      </div>

      <div style="border: 1px solid #ccc">
        <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editorRef"
            :defaultConfig="toolbarConfig"
            :mode="mode"
        />
        <Editor
            style="height: 500px; overflow-y: hidden;"
            v-model="valueHtml"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="handleCreated"
        />
      </div>

      <div class="form-group">
        <label for="author">作者:</label>
        <input type="text" id="author" v-model="formData.author" required>
      </div>

      <div class="form-group">
        <label for="uploadTime">上传时间:</label>
        <input type="text" id="uploadTime" v-model="formData.uploadTime" required>
      </div>

      <div class="form-group">
        <label for="image">图片上传:</label>
        <input type="file" id="image" @change="handleImageUpload" required>
      </div>

      <div class="form-group">
        <label for="type">选择文件上传至</label>
        <select id="category" v-model="formData.category" required>
          <option value="solution">解决方案</option>
          <option value="news">新闻中心</option>
        </select>
      </div>

      <button type="submit">提交</button>
    </form>
  </div>
</template>

<script>
// 该模板用于使用wangeditor富文本编辑器，用于对文章进行上传
import '@wangeditor/editor/dist/css/style.css' // 引入 css

import {onBeforeUnmount, ref, shallowRef, onMounted, reactive} from 'vue'
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import axios from "axios";

export default {
  components: {Editor, Toolbar},
  setup() {
    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()

    // 内容 HTML
    const valueHtml = ref('')

    // 模拟 ajax 异步获取内容
    onMounted(() => {
      setTimeout(() => {
        // valueHtml.value = '<p>模拟 Ajax 异步设置内容</p>';
        getCurrentTime()
      }, 1500)
    })

    const toolbarConfig = {}
    const editorConfig = {placeholder: '请输入内容...', MENU_CONF:{}}
    editorConfig.MENU_CONF['uploadImage'] = {
      server: 'http://120.26.199.34:5000/upImage',
    }

    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
      const editor = editorRef.value
      if (editor == null) return
      editor.destroy()
    })

    const handleCreated = (editor) => {
      editorRef.value = editor // 记录 editor 实例，重要！
    }

    const formData = reactive({
      title: '',
      summary: '',
      author: 'Admin',
      uploadTime: getCurrentTime(),
      image: null,
      html_text: '',
      category: ''
    })

    // 对事件进行处理
    function getCurrentTime() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      return `${year}_${month}_${day}_${hours}_${minutes}`;
    }

    function handleImageUpload(event) {
      const file = event.target.files[0];
      formData.image = file;
    }

    async function handleSubmit() {
      const data = new FormData();
      data.append('title', formData.title);
      data.append('summary', formData.summary);
      data.append('author', formData.author);
      data.append('uploadTime', formData.uploadTime);
      data.append('image', formData.image);
      data.append("htmltext", valueHtml.value);
      data.append("category", formData.category)

      try {
        // const response = await axios.post('http://127.0.0.1:5000/upload', data, {
        const response = await axios.post('http://120.26.199.34:5000/upload', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        alert("上传成功");
        console.log('上传成功:', response.data);
      } catch (error) {
        alert("上传失败");
        console.error('上传失败:', error);
      }
    }

    return {
      editorRef,
      valueHtml,
      mode: 'default', // 或 'simple'
      toolbarConfig,
      editorConfig,
      handleCreated,
      formData,
      handleImageUpload,
      handleSubmit
    };
  }
}
</script>
<style scoped>
.upload-form{
  width: 70%;
  margin: auto;
  margin-top: 80px;
}
.upload-form > form> div{
  margin: 30px auto ;
}
.form-group > label{
  display: inline-block;
  margin: auto 30px;
  font-size: 20px;
  width: 90px;
}
.form-group > input{
  width: 50%;
  height: 40px;
}
.form-group > textarea{
  min-width: 50%;
  max-width: 80%;
  max-height: 400px;
  min-height: 30px;
}
.form-group > select{
  height: 30px;
  width: 90px;
}
.upload-form > form > button{
  border: none;
  height: 40px;
  width: 90px;
  background-color: #e6a100;
  margin-left: 50%;
  margin-bottom: 50px;
}
</style>