<template>
  <head_top/>
  <head_bottom />
  <article_page />
  <page_footer />
<!--  <test_page />-->
</template>
<script>
// 主页
import head_top from "../components/head_top.vue"
import head_bottom from "../components/head_bottom.vue"
import article_page from "@/components/article_page.vue";
import page_footer from "@/components/page_footer.vue";
// import test_page from "@/components/test_page.vue";
export default {
  name: "HomePage",
  components:{
    head_top,
    head_bottom,
    article_page,
    page_footer,
    // test_page
  }
}
</script>
<style>
*{
  padding: 0;
  margin: 0;
}
</style>