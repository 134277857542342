<script>
export default {
  name:"about_page",
  data(){
    return{
      page_texts:[
        {
          title: "产品技术",
          Sublabel: "在线近红外光谱仪",
          text: [""],
          picture_path: [require("@/assets/about_temple/production.png")],
          option: {
            if_title_decoration: true,
          }
        }]
    }
  }
}
</script>
<template>
  <div class="about-content" v-for="page_text in page_texts" :key="page_text.title">
    <h1>{{page_text.title}}</h1>
    <div class="title_decoration" v-if="page_text.option.if_title_decoration"></div>
    <div v-else style="margin-bottom: 60px"></div>
    <h3>{{page_text.Sublabel}}</h3>
    <p v-for="(text, index) in page_text.text" :key="index">{{text}}</p>
    <div class="picture_box" >
      <div v-for="(path, index) in page_text.picture_path" :key="index" :style="{background: `url(${path})`}" style="background-size: contain;background-repeat: no-repeat;background-position: center"></div>
    </div>
  </div>
</template>

<style scoped>
.about-content{
  display: flex;   /*为page_text这个元素可以填充剩余空间*/
  flex-direction: column;
  height: 100vh;
}
.about-content > h1{
  text-align: center;
  margin-top: 80px;
}
.about-content > .title_decoration{ /*标题装饰*/
  width: 80px;
  height: 3px;
  background: #fd710d;
  margin: 10px auto 40px auto;
}
.about-content > h3{
  text-align: center;
  font-weight: normal;
}
.about-content > p{
  color: rgb(99, 99, 108);
  font-size: 15px;
  text-indent: 40px;
  line-height: 30px;
  margin: 0 120px;
}
.picture_box{
  flex: 1;      /* 填充*/
  display: flex;
}
.picture_box > *{ /*这些元素的属性无法使用background来调整图片，需要在template中调整*/
  flex: 1;
  margin: 40px;
}
</style>