<script>
import online_1001 from "@/views/index/product/online_1001.vue";

export default {
  name: "index_page",
  components:{
    online_1001
  },
  methods:{
    prevSilde(){
      if(this.currentIndex > 0){
        this.currentIndex --;
      }else{
        this.currentIndex = this.overallPage - 1
      }
    },
    nextSlide(){
      if(this.currentIndex < this.overallPage -1){
        this.currentIndex ++;
      }else{
        this.currentIndex = 0;
      }
    }
  },
  data(){
    return{
      currentIndex: 0,
      overallPage: 3,
    }
  }
}
</script>

<template>
  <div class="index_page">
    <button @click="prevSilde" class="sliding left_sliding">&#60;</button>
    <button @click="nextSlide" class="sliding right_sliding">&#62;</button>
    <div class="sliding_page" :style="{transform: `translateX(-${currentIndex * 100}%)` }">
      <online_1001 />
      <online_1001 />
      <online_1001 />
      <div></div>
    </div>
  </div>
</template>
<style scoped>
.index_page{
  margin: auto;
  height: 680px;
  width: 1500px;
  overflow: hidden;
  position: relative;
}
.sliding_page{
  display: flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: -moz-flex;
  transition: transform 1s ease-in-out;
}
.sliding_page > *{
  min-width: 100%;
  box-sizing: border-box;
}
.sliding{
  position: absolute;
  top: 50%;
  background: rgba(99, 99, 108, 0.46);
  width: 60px;
  height: 70px;
  color: #ffffff;
  border: none;
  font-size: 30px;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  z-index: 20;
  cursor: pointer;
}
.sliding:hover{
  color: #ffb300;
}
.index_page:hover .sliding{
  opacity: 1;
}
.right_sliding{
  right: 20px;
}
.left_sliding{
  left: 20px;
}

</style>