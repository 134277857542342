<template>
  <index_page />
  <about_page/>
  <subject_temp_view :is_at_homepage=true post_name="solution" />
  <subject_temp_view :is_at_homepage=true post_name="news" />
<!--  <test_page />-->
</template>

<script>
import index_page from "@/views/index/index_page.vue";
import about_page from "@/views/about/about_page.vue"
import subject_temp_view from "@/views/subject/subject_temp_view.vue";
// import test_page from "@/components/test_page.vue";
export default {
  name: "article_page",
  components:{
    index_page,
    about_page,
    subject_temp_view
    // test_page
  }
}
</script>

<style>

</style>