<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  created(){
    document.title = "安徽中科谱康科技有限公司";
  }
}
</script>