<script>
/**
 * 向后端请求html文件，并展示
 * 需要正确的路由才能调用后端文件
 */
import head_bottom from "@/components/head_bottom.vue";
import head_top from "@/components/head_top.vue";
import axios from "axios";

export default {
  name: "articleHtmlTemplate",
  components: {head_top, head_bottom},
  data(){
    return{
      htmlData: "载入数据中...."
    }
  },
  created() {
    this.gethtmlfile()
  },
  methods:{
    gethtmlfile(){
      const path = 'http://120.26.199.34:5000/article_template/' + this.$route.params.words + '?key=' + this.$route.query.key + '&filetype=html';
      axios.get(path).then(res => {
        this.htmlData = res.data.replace(/127.0.0.1/g, '120.26.199.34');
      }).catch(error => {
        console.log("无法接收到请求", error)
      })
    }
  }

}

</script>

<template>
  <head_top />
  <head_bottom />
  <div v-html="htmlData" class="text_html">
  </div>
</template>

<style>
.text_html{
  width: 1200px;
  margin: auto;
}
.text_html p:has(img){
  display: flex;
  align-items: center;
}
.text_html img {
  max-width: 700px;
  margin: auto;
}
.text_html p{
  margin: 20px auto 10px auto;
  line-height: 40px;
}
.text_html h1{
  margin: 40px auto;
}
.text_html h3{
  margin: 30px auto;
}
</style>